/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box, Button, Image,
  Flex,
  Heading, Icon, SimpleGrid, Text, VStack, useToast
} from '@chakra-ui/react'
import { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FiCheck, FiCheckCircle, FiX } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { Input } from '../../Components/Form/Input'
import { Loader } from '../../Components/Loader'
import IFilesProps from '../../interfaces/IFilesProps'
import { Pagination } from '../../Components/Pagination'
import { v4 as uuid } from 'uuid'

const ImagesBank: FC = () => {
  const [loading, setLoading] = useState(false)
  const [imageName, setImageName] = useState('')
  const [files, setFiles] = useState<IFilesProps[]>([])
  const params = useParams()
  const [page, setPage] = useState(1)
  const [selectedItem, setSelectedItem] = useState<IFilesProps>({} as IFilesProps)
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const toast = useToast()

  const [showPaidModal, setShowPaidModal] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const DragRef = useRef<DragAndDropExportedProps>(null)

  const load = useCallback(async (pag: number) => {
    try {
      const { data } = await api.get('/files', {
        params: {
          type: 'blog-content',
          pag
        }
      })

      setPage(pag)
      setFiles(data.data)
      setTotal(data.total)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      if (!DragRef?.current?.selectedFile) {
        toast({
          title: 'Por favor, selecione uma imagem.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (!imageName) {
        toast({
          title: 'Por favor, preench o nome de referência.',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      try {
        const { data: dados } = await api.post('/files', { name: imageName, type: 'blog-content' })

        if (!dados?.isValid) {
          toast({
            title: 'Já existe uma imagem com este nome. Por favor, escolha outro.',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          return
        }

        const id = uuid()
        setLoading(true)

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragRef]

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                toast({
                  title: 'Imagem cadastrada com sucesso',
                  position: 'top',
                  isClosable: true,
                  status: 'success',
                  variant: 'solid'
                })
                // navigate('/blog/posts')
                setLoading(false)
                setImageName('')
                DragRef?.current?.cleanInput()
                setValue('name', '')
                load(1)
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${String(id)}`, cb)
        }))
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, imageName, load, setValue]
  )

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    if (!selectedItem || !selectedItem?.id) return

    const { id } = selectedItem
    try {
      await api.delete(`/files/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as IFilesProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as IFilesProps)
    }
  }, [selectedItem, toast, page, load])

  return (
    <Box>
      <Header />
      {loading && <Loader />}

      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Imagem"
          isOpen={!!selectedItem?.id}
          cancelFunction={() => setSelectedItem({} as IFilesProps)}
          nextFunction={hanleDelete}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >

          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="normal">
              <Text fontSize="21px" fontWeight="bold" color="green.dark"> Bando de Imagens</Text>
            </Heading>
          </Flex>

          <Box mt="50px" alignItems="left">
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                label="Imagem"
                ref={DragRef}
                defaultCover={initialCover}
                payload={{
                  name: imageName,
                  type: 'blog-content'
                }}
              />

              <Box>
                <Input label="Nome de referência" {...register('name')} placeholder="Preencha o nome" onChange={(e: any) => setImageName(e?.target?.value)} />
                <Button
                  width="200px"
                  as="button"
                  type="submit"
                  fontSize="sm"
                  bg="green.dark"
                  mt="10"
                  _hover={{ bgColor: 'green.teal' }}
                  color="green.light"
                  // leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                  // onClick={() => handleNewOption(item.id)}
                  >
                  Cadastrar
                </Button>
              </Box>

            </SimpleGrid>

          </Box>

          <Box mt="50px" w="100%" mb="50px">
            <Heading size="lg" fontWeight="normal" color="green.dark" >
              <Text fontSize="21px" color="gray.500" fontWeight="bold" align="left">Imagens Cadastradas</Text>
            </Heading>

              <Flex marginTop="50px" marginBottom="50px" alignItems="flex-start" justifyContent="flex-start" align="left" gap="24px" flexWrap="wrap">
                {files?.map(file => (
                  <Box key={file?.id} display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                    <Image src={file?.link} width={180} height={180} fit="contain" />
                    <Text align="center">{file?.name}</Text>

                    <Button
                    width="150px"
                    as="button"

                    fontSize="sm"
                    bg="green.dark"
                    mt="10"
                    onClick={() => setSelectedItem(file)}
                    _hover={{ bgColor: 'green.teal' }}
                    color="green.light"
                    >
                    Deletar
                  </Button>
                  </Box>
                ))}

              </Flex>

          </Box>

          <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
        </Box>
      </Flex>

    </Box>
  )
}

export { ImagesBank }
