/* eslint-disable import/extensions */
import React, {
  FC,
  createContext,
  useCallback,
  useState,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import api from "../Services/api";
import { useToast } from "@chakra-ui/react";

export const multiOptionsConcierge = [
  {
    label: "Selecione",
    value: "",
  },
  {
    label: "Sim",
    value: "YES",
  },
  {
    label: "Não",
    value: "NO",
  },
];

export const typeOptionsConcierge = [
  {
    label: "Selecione",
    value: "",
  },
  // {
  //   label: 'Campo Numérico',
  //   value: 'NUMERIC'
  // },
  {
    label: "Descritivo",
    value: "DESCRIPTION",
  },
  {
    label: "de X até Y",
    value: "RANGE",
  },
];

export interface IPriceHouseProps {
  house: {
    id: string;
    name: string;
  };
  price?: number;
}

export type IItemType = "NUMERIC" | "DESCRIPTION" | "RANGE" | undefined;

export interface IOptionProps {
  id: string;
  name?: string;
  help?: string;
  availability?: IHouseProps[];
  prices?: IPriceHouseProps[];
  from?: number;
  to?: number;
  type: IItemType;
}

export interface IItemProps {
  id: string;
  name?: string;
  multi?: "YES" | "NO" | undefined; // utilizado quando o tipo for descritivo
  type?: IItemType;
  description?: string;
  min?: number | undefined; // utilizado quando o tipo for numérico
  max?: number | undefined; // utilizado quando o tipo for numérico
  options?: IOptionProps[];
}

export interface IHouseProps {
  name: string;
  id: string;
}

export interface IInfoProps {
  name?: string;
  photo?: string;
  availability?: IHouseProps[];
  prices?: IPriceHouseProps[];
  need_time: "YES" | "NO";
}

export interface IRulesOptions {
  id: string;
  description: string;
}

export interface IConciergeProps {
  info?: IInfoProps;
  items?: IItemProps[];
  rules?: IRulesOptions;
}

interface ConciergeContextProps {
  concierge: IConciergeProps;
  updateConcierge: (data: IConciergeProps) => void;
  tempItem: IItemProps;
  updateTempItem: (data: IItemProps) => void;
}
const ConciergeContext = createContext<ConciergeContextProps>(
  {} as ConciergeContextProps
);

export const ConciergeProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [concierge, setConcierge] = useState<IConciergeProps>(
    {} as IConciergeProps
  );
  const [tempItem, setTempItem] = useState<IItemProps>({} as IItemProps);

  const updateConcierge = useCallback((data: IConciergeProps): void => {
    setConcierge(data);
  }, []);

  const updateTempItem = useCallback((data: IItemProps): void => {
    setTempItem(data);
  }, []);

  return (
    <ConciergeContext.Provider
      value={{ concierge, updateConcierge, tempItem, updateTempItem }}
    >
      {children}
    </ConciergeContext.Provider>
  );
};

export const useConcierge = (): ConciergeContextProps => {
  const context = useContext(ConciergeContext);

  if (!context) {
    throw new Error("useConcierge must be used inside an ConciergeProvider");
  }

  return context;
};
