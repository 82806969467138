/* eslint-disable import/extensions */
/* eslint-disable multiline-ternary */
import React, { useCallback, useEffect, useState } from 'react'
import { CalendarSchedule } from '../../Components/CalendarSchedule'
import { Box, Flex, Heading, Link, Spinner, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tbody, Td, Th, Thead, Tr, Text, Icon, Button } from '@chakra-ui/react'
import { Sidebar } from '../../Components/Sidebar'
import { Header } from '../../Components/Header'
import { Pagination } from '../../Components/Pagination'
import { RiEye2Fill } from 'react-icons/ri'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import api from '../../Services/api'
import { format } from 'date-fns'

const Schedule = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<ISchedulesProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/schedules', {
        params: {
          pag
        }
      })

      const normalize = data?.data?.map((schedule: ISchedulesProps) => {
        return {
          ...schedule
          //   date_initial: format(new Date(schedule.date_initial), 'dd/MM/yyyy'),
          //   date_final: format(new Date(schedule.date_final), 'dd/MM/yyyy'),
        //   created_at: format(new Date(schedule.created_at), 'dd/MM/yyyy')
        }
      })

      console.log('normalize: ', normalize)

      setPage(pag)
      setData(normalize)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar reservas: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load(page)
  }, [page])

  return (
    <Box>
        <Header />

        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
            <Sidebar />

            <Box flex="1" borderRadius={8} bg="white" p="8">
                <Flex mb="8" justify="space-between" align="center" flex="1">
                    <Tabs variant='enclosed' w="100%">
                        <TabList>

                        <Tab>Agenda</Tab>
                        <Tab>Lista</Tab>

                        </TabList>
                        <TabPanels>

                        <TabPanel>
                            <Heading size="md" fontWeight="bold" color="gray.500" my="5">
                                Reservas - Modo Agenda
                            </Heading>
                            <CalendarSchedule schedule={data} />
                        </TabPanel>

                        <TabPanel>
                            <Heading size="md" fontWeight="bold" color="gray.500" my="5">
                                Reservas - Modo Lista
                            </Heading>

                            {loading ? (
                                <Flex justify="center">
                                <Spinner />
                                </Flex>
                            ) : error ? (
                                <Flex justify="center">
                                <Text>Erro ao carregar a lista de reservas</Text>
                                </Flex>
                            ) : (
                                <>
                                    <Table colorScheme="blue">
                                        <Thead>
                                        <Tr>
                                            <Th textAlign="center">Check In</Th>
                                            <Th textAlign="center">Check Out</Th>
                                            <Th textAlign="center">Casa</Th>
                                            <Th textAlign="center">Sócio</Th>
                                            <Th textAlign="center">Data de Criação</Th>
                                            <Th textAlign="center">Status</Th>
                                            <Th w="6"></Th>
                                        </Tr>
                                        </Thead>
                                        <Tbody>
                                        {data.map((i) => (
                                            <Tr key={i.id}>

                                            <Td>
                                                <Link color="green.dark" textAlign="center">
                                                    <Text fontWeight="bold" >{i.date_initial}</Text>
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Link color="green.dark" textAlign="center">
                                                    <Text fontWeight="bold" >{i.date_final}</Text>
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Link color="green.dark" textAlign="center">
                                                    <Text fontWeight="bold" fontSize="14px" >{i.property.name}</Text>
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Link color="green.dark" textAlign="center">
                                                    <Text fontWeight="bold" fontSize="14px" >{i.user.name}</Text>
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Link color="green.dark">
                                                    <Text fontWeight="bold" textAlign="center" >{i?.created_at}</Text>
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Link color="green.dark" textAlign="center">
                                                    <Text fontWeight="bold" >{i.status}</Text>
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Flex>
                                                    <Button
                                                        href={`/schedule/details/${i?.id}`}
                                                            as="a"
                                                            size="sm"
                                                            fontSize="sm"
                                                            mr="2"
                                                            bg="green.teal"
                                                            leftIcon={
                                                            <Icon as={RiEye2Fill} color="green.dark" fontSize="16" />
                                                            }
                                                        >
                                                        Visualizar
                                                    </Button>
                                                </Flex>
                                            </Td>
                                            </Tr>
                                        ))}
                                        </Tbody>
                                    </Table>

                                    <Pagination
                                        totalCountOfRegisters={total}
                                        currentPage={page}
                                        onPageChange={setPage}
                                    />
                                </>
                            )}
                        </TabPanel>

                        </TabPanels>
                    </Tabs>
                </Flex>
            </Box>
        </Flex>
    </Box>

  )
}

export { Schedule }
