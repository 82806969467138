/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import api from '../../Services/api'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import {
  Box, Button, Flex, Heading, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tbody, Td, Text, Th, Thead, Tr, VStack, Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel

} from '@chakra-ui/react'
import { Pagination } from '../Pagination'
import { RiEye2Fill } from 'react-icons/ri'
import { AiOutlineDelete } from 'react-icons/ai'
import { format } from 'date-fns'
import { FormatMoney } from '../../utils/formatMoney'
import IScheduleConciergeProps, { IScheduleConciergeOptionsProps } from '../../interfaces/IScheduleConciergeProps'
import { Select } from '../Form/Select'
import { getConciergeStatus } from '../../utils/getConciergeStatus'

interface Props {
  appointment_id: string
  onClose: () => void
}

const statusOptions = [
  {
    label: 'Solicitado',
    value: 'CREATED'
  },
  {
    label: 'Confirmado',
    value: 'CONFIRMED'
  },
  {
    label: 'Pago',
    value: 'PAID'
  },
  {
    label: 'Cancelado',
    value: 'CANCELED'
  }
]

const ModalAppointmentDetails: FC<Props> = ({ appointment_id, onClose }) => {
  const [error, setError] = useState(false)
  const [data, setData] = useState<ISchedulesProps>({} as ISchedulesProps)
  const [loading, setLoading] = useState(false)

  console.log('appointment_id: ', appointment_id)

  const loadSchedule = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/schedules/${appointment_id}`)
      console.log('data: ', data)

      const normalize = {
        ...data,
        date_initial: format(new Date(data?.checkIn), 'dd/MM/yyyy'),
        date_final: format(new Date(data?.checkOut), 'dd/MM/yyyy'),
        services: data.services.map((item: any) => {
          return {
            ...item,
            dates: item.dates.map((date: any) => format(new Date(date), 'dd/MM/yyyy'))
          }
        })
      }

      setData(normalize)
    } catch (error) {
      console.log('erro ao pegar reserva: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [appointment_id])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadSchedule()
  }, [])

  const normalizePrice = useCallback((conciergeItem: IScheduleConciergeProps) => {
    const sumAmountItems: string[] = []

    const subItensSelected = conciergeItem.options

    subItensSelected?.forEach(item => {
      sumAmountItems.push(item.price)
    })

    const sumValues = sumAmountItems
      .map(Number)
      .reduce((acc, val) => acc + val, 0)
    const basePriceMoreDays = Object.keys(conciergeItem.dates).length * Number(conciergeItem.price)
    return basePriceMoreDays + sumValues
  }, [])

  const handleChangeStatus = useCallback((value: any, concierge: any) => {
    console.log('handleChangeStatus value: ', value)
    console.log('handleChangeStatus concierge: ', concierge)
    if (value === '') return

    if (value !== concierge.status) {
      console.log('troucar')
    }
  }, [])

  return (
        <Modal isOpen onClose={onClose} size="6xl">
        <ModalOverlay />
        <Box

        flex="1"
        borderRadius={8}
        bg="white"
        p="8"

      >
        <ModalContent>
          <ModalHeader mb="0" pb="0">Detalhes do Agendamento</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
          <Box flex="1" borderRadius={8} bg="white" p="8">

            { loading ? (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            ) : error ? (
                <Flex justify="center">
                    <Text>Erro ao buscar os dados deste agendamento.</Text>
                </Flex>
            ) : (
                <>

                <Box flex="1" borderRadius={8} bg="white">
                    <Flex mb="8" justify="space-between" align="center">
                    <Heading fontSize="25px" fontWeight="bold" color="gray.500">
                    Dados da Reserva
                    </Heading>
                    </Flex>
                </Box>

                    <VStack spacing={['12', '12']} mb="50px">
                        <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" >
                                <VStack>

                                <Box width="200px" height="80px">
                                    <Text fontSize="21px" color="green.teal" fontWeight="bold">Casa</Text>
                                    <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.property?.name}</Text>
                                </Box>

                                <Box width="200px" height="80px">
                                    <Text fontSize="21px" color="green.teal" fontWeight="bold">Sócio</Text>
                                    <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.user?.name}</Text>
                                    <Text fontSize="16px" color="green.dark" fontWeight="400">{data?.user?.phone}</Text>
                                </Box>

                                </VStack>

                                <VStack>

                                <Box width="200px" height="80px">
                                    <Text fontSize="21px" color="green.teal" fontWeight="bold">Check In</Text>
                                    <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.date_initial}</Text>
                                </Box>

                                <Box width="200px" height="80px">
                                    <Text fontSize="21px" color="green.teal" fontWeight="bold" align="left">Cota</Text>
                                    <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.quota?.name}</Text>
                                </Box>

                                </VStack>

                                <VStack>

                                <Box width="250px" height="80px">
                                    <Text fontSize="21px" color="green.teal" fontWeight="bold">Check Out</Text>
                                    <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.date_final}</Text>
                                </Box>

                                <Box width="250px" height="80px">
                                    <Text fontSize="21px" color="green.teal" fontWeight="bold">Tipo de Reserva</Text>
                                    <Text fontSize="18px" color="green.dark" fontWeight="bold">{data?.type?.name}</Text>
                                </Box>

                                </VStack>

                        </SimpleGrid>

                    </VStack>

                    <Box flex="1" mt="10" mb="8" borderRadius={8} bg="white">
                    <Flex justify="space-between" align="center">
                        <Heading fontSize="25px" fontWeight="bold" color="gray.500">
                        Informações
                        </Heading>
                    </Flex>
                    </Box>

                    <Tabs variant='enclosed'>
                        <TabList>
                            <Tab>Serviços de concierge solicitados</Tab>
                            <Tab>Histórico de Pagamentos</Tab>

                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Accordion allowMultiple mt="30px">

                                { data?.services?.map(i => (
                                    <AccordionItem key={i.id}>

                                        <AccordionButton>
                                            <Box as='span' flex='1' textAlign='left'>
                                            {i.concierge?.name}
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>

                                        <AccordionPanel pb={4}>
                                        <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" mt="30px" >
                                        <Box width="200px" height="80px">
                                                <Text fontSize="21px" color="green.teal" fontWeight="bold">Status Atual</Text>
                                                <Text fontSize="18px" color="green.dark" fontWeight="bold">{getConciergeStatus(i.status)}</Text>
                                            </Box>

                                        <Select name='status' options={statusOptions} onChange={e => handleChangeStatus(e.target.value, i)} label='Mudança de status' />
                                        </SimpleGrid>

                                        <Table colorScheme="blue" mt="50px">
                                                <Thead>
                                                    <Tr>

                                                        <Th textAlign="left" backgroundColor="green.light" color="black" fontWeight="700">Nome</Th>
                                                        <Th w={8} textAlign="center" backgroundColor="green.light" color="black" fontWeight="700">Valor</Th>

                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                <Tr bg="green.dark">
                                                    <Td>

                                                        <Text fontWeight="700" color="white" >DATAS SELECIONADAS</Text>

                                                    </Td>

                                                    <Td>

                                                    </Td>
                                                </Tr>

                                                {i?.dates?.map(date => (
                                                    <Tr bg="gray.100" key={date}>
                                                    <Td>

                                                        <Text fontWeight="700" color="black" ml="15px">{date}</Text>

                                                    </Td>
                                                    <Td> <Text fontWeight="700" color="black" textAlign="center" >{i.price && i.price !== '0.00' ? FormatMoney(Number(i.price)) : '--'}</Text></Td>
                                                </Tr>
                                                ))}

                                                <Tr bg="green.dark">
                                                    <Td>

                                                        <Text fontWeight="700" color="white">HORÁRIO</Text>

                                                    </Td>

                                                    <Td>

                                                    </Td>
                                                </Tr>

                                                    <Tr bg="gray.100" >
                                                    <Td>

                                                        <Text fontWeight="700" color="black" ml="15px">{i.hour}</Text>

                                                    </Td>

                                                    <Td>
                                                    <Text fontWeight="700" color="black" textAlign="center" >--</Text>
                                                    </Td>
                                                </Tr>

                                                {i.options?.map(option => (
                                                    <>
                                                        <Tr bg="green.dark">
                                                            <Td>

                                                                <Text fontWeight="700" color="white">{option.question.name.toUpperCase()}</Text>

                                                            </Td>

                                                            <Td>

                                                            </Td>
                                                        </Tr>

                                                        <Tr bg="gray.100" >
                                                            <Td>

                                                                <Text fontWeight="700" color="black" ml="15px">{option.answer?.name}</Text>

                                                            </Td>

                                                            <Td>
                                                                <Text fontWeight="700" color="black" ml="15px" fontSize="16px">{FormatMoney(Number(option.price))}</Text>

                                                            </Td>
                                                        </Tr>

                                                    </>

                                                ))}

                                                <Tr bg="green.light" >
                                                    <Td>

                                                        <Text fontWeight="700" color="black" ml="15px">TOTAL DO SERVIÇO</Text>

                                                    </Td>

                                                    <Td>
                                                    <Text fontWeight="700" color="black" textAlign="center" fontSize="18px" >{FormatMoney(normalizePrice(i))}</Text>
                                                    </Td>
                                                </Tr>

                                                </Tbody>
                                        </Table>

                                        </AccordionPanel>
                                    </AccordionItem>
                                ))}

                                </Accordion>

                                    {/* <Table colorScheme="blue">
                                        <Thead>
                                            <Tr>

                                                <Th>Serviço</Th>

                                                <Th>Status</Th>
                                                <Th>Ações</Th>
                                                <Th w="8"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>

                                            { data?.services?.map(i => (
                                                <Tr key={i.id}>
                                                    <Td>
                                                        <Box><Text fontWeight="bold" color="blue.500">{i?.concierge?.name}</Text></Box>
                                                    </Td>

                                                        <Td>
                                                        <Text fontWeight="bold">{i?.status}</Text>
                                                        </Td>

                                                    <Td>
                                                    <Button
                                                        onClick={() => {}}
                                                        size="sm"
                                                        fontSize="sm"
                                                        mr="2"
                                                        bg="green.teal"
                                                        colorScheme="wine"
                                                        leftIcon={
                                                        <Icon as={RiEye2Fill} fontSize="16" />
                                                        }
                                                    >
                                                    Visualizar
                                                    </Button>
                                                    </Td>
                                                <Flex>

                                            </Flex>

                                            </Tr>

                                            ))}

                                        </Tbody>
                                    </Table> */}

                                    {/* <Pagination
                                        totalCountOfRegisters={totalPlans}
                                        currentPage={pagePlans}
                                        onPageChange={setPagePlans}
                                    /> */}

                            </TabPanel>

                            <TabPanel>
                                <>
                                <Flex w="100%" justifyContent="flex-end">
                                <Button
                                            onClick={() => {}}
                                            size="sm"
                                            fontSize="sm"
                                            mr="2"
                                            bg="green.teal"
                                            colorScheme="wine"
                                            leftIcon={
                                            <Icon as={RiEye2Fill} fontSize="16" />
                                            }
                                        >
                                        Adicionar
                                        </Button>
                                </Flex>
                                    <Table colorScheme="blue">
                                        <Thead>
                                            <Tr>
                                                <Th>Tipo</Th>
                                                <Th>Data</Th>
                                                <Th>Valor</Th>
                                                <Th>Status</Th>

                                                <Th w="8"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>

                                            {/* { dataClasses.map(i => (
                                                <Tr key={i.index}>
                                                    <Td>
                                                        <Box><Text fontWeight="bold" color="blue.500">{i?.date} - {i?.hour?.hour}</Text></Box>
                                                    </Td>
                                                    <Td>
                                                        <Text fontWeight="bold">{i?.hour?.name} - {i?.hour?.teacher}</Text>
                                                    </Td>

                                                    <Td>
                                                        <Text fontWeight="bold">{i?.status_class.name}</Text>
                                                    </Td>

                                                    <Td>
                                                    <Flex>
                                                    <Button as="button" onClick={() => {}} size="sm" fontSize="sm" colorScheme="red" leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}>
                                                                Excluir
                                                            </Button>
                                                    </Flex>
                                                    </Td>
                                                </Tr>

                                            ))} */}
                                        </Tbody>
                                    </Table>

                                    {/* <Pagination
                                        totalCountOfRegisters={totalClasses}
                                        currentPage={pageClasses}
                                        onPageChange={setPageClasses}
                                    /> */}
                                </>
                            </TabPanel>

                        </TabPanels>
                    </Tabs>
                </>
            )}

</Box>

          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>
              Fechar
            </Button>
            {/* <Button
                  type="submit"
                  bg="green.dark"
                  _hover={{ bgColor: 'green.teal' }}
                  color="green.light"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button> */}

          </ModalFooter>

        </ModalContent>
        </Box>
      </Modal>

  )
}

export { ModalAppointmentDetails }
