/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex, Heading, HStack, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SimpleGrid,
  Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast, VStack
} from '@chakra-ui/react'
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { BillsTable } from '../../Components/BillsTable'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Input } from '../../Components/Form/Input'
import { MoneyInput } from '../../Components/Form/MoneyInput'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { Sidebar } from '../../Components/Sidebar'
import IBillsHouseImportedDTO from '../../interfaces/IBiilsHouseImportedDTO'
import IOptionsProps from '../../interfaces/IOptionsProps'
import api from '../../Services/api'

const FinancialHousesCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [createdAt, setCreatedAt] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [year, setYear] = useState(() => {
    const today = new Date()
    return String(today.getFullYear())
  })
  const [month, setMonth] = useState(() => {
    const today = new Date()
    return String(today.getMonth() + 1).padStart(2, '0')
  })
  const params = useParams()

  const toast = useToast()
  const navigation = useNavigate()
  const DragRef = useRef<DragAndDropExportedProps>(null)
  const DragBillsRef = useRef<DragAndDropExportedProps>(null)
  const [showDeleteBillsModalConfirmation, setShowDeleteBillsModalConfirmation] = useState(false)
  const [bills, setBills] = useState<IBillsHouseImportedDTO>({} as IBillsHouseImportedDTO)
  const [clientsOptions, setClientsOptions] = useState<IOptionsProps[]>([])
  const { register, handleSubmit, formState, setValue, unregister } = useForm()
  const { errors } = formState

  const loadBills = useCallback(async () => {
    try {
      const resp = await api.get(`/bills/${String(params.property_id)}?month=${month}&year=${year}`)
      const data: IBillsHouseImportedDTO = resp.data

      if (data?.year) {
        setBills(data)
      } else {
        setBills({} as IBillsHouseImportedDTO)
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, params, month, year])

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values): Promise<void> => {
      console.log('chamou')
      try {
        setLoading(true)
        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragBillsRef, DragRef]

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        console.log('normalizeInputsWithContent:', normalizeInputsWithContent)
        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? async () => {
              toast({
                title: 'Cadastrado com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })
              // navigate('/blog/posts')
              setLoading(false)

              // DragRef?.current?.cleanInput()
              // setValue('name', '')
              await loadBills()
            }
            : () => {}

          if (index === 0) {
            // fazendo upload da planilha
            inputDrag?.current?.execute(`/bills/upload/?property_id=${String(params.property_id)}&month=${month}&year=${year}`, cb)
          } else {
            // upload do comprovante
            // inputDrag?.current?.execute(`/files/${String(id)}`, cb)
          }
        }))
        // loadBills()
      } catch (error) {
        console.log('error: ', error)
        setLoading(false)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
      } finally {
        setLoading(false)
      }
    },
    [toast, params, loadBills, month, year]
  )

  const handleDeleteBills = useCallback(async () => {
    console.log('remover todas as contas')
    try {
      setShowDeleteBillsModalConfirmation(false)
      setLoading(true)
      await api.delete('/bills', {
        params: {
          property_id: String(params.property_id),
          month,
          year
        }
      })

      await loadBills()
    } catch (error) {
      console.log('error handleDeleteBills: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [month, year, params, loadBills])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadBills()
  }, [month, year])

  return (
    <Box>
      <Header />
      {loading && <Loader />}

      {showDeleteBillsModalConfirmation && (
        <AlertDialogCustom
          title="Remover Contas"
          description="Você tem certeza que deseja remover todas as contas deste mês?"
          isOpen={showDeleteBillsModalConfirmation}
          cancelFunction={() => setShowDeleteBillsModalConfirmation(false)}
          nextFunction={handleDeleteBills}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >

          <Flex justifyContent="space-between" alignItems="center" flex={1} >
              <Heading size="md" fontWeight="bold" color="green.teal">Relação de custo mensal: Casa Minke</Heading>

                <Flex alignItems="center">
                  {bills?.SALDO && (
                    <Button
                      onClick={() => setShowDeleteBillsModalConfirmation(true)}

                      fontSize="sm"
                      bg="green.dark"
                      _hover={{ bgColor: 'green.light' }}
                      color="white"
                      w={300}
                      marginRight={15}

                    >
                      Remover todas as contas deste mês
                    </Button>
                  )}

                  <Select w={100} placeholder='Ano' onChange={e => setYear(e.target.value)} defaultValue={year} value={year}>
                    <option value='2024'>2024</option>
                    {/* <option value='option2'>Option 2</option>
                    <option value='option3'>Option 3</option> */}
                  </Select>
                </Flex>

          </Flex>

          <Tabs mt="30px" defaultIndex={Number(month) - 1} index={Number(month) - 1} >
            <TabList>
              <Tab onClick={() => setMonth('01')}>Janeiro</Tab>
              <Tab onClick={() => setMonth('02')}>Fevereiro</Tab>
              <Tab onClick={() => setMonth('03')}>Março</Tab>
              <Tab onClick={() => setMonth('04')}>Abril</Tab>
              <Tab onClick={() => setMonth('05')}>Maio</Tab>
              <Tab onClick={() => setMonth('06')}>Junho</Tab>
              <Tab onClick={() => setMonth('07')}>Julho</Tab>
              <Tab onClick={() => setMonth('08')}>Agosto</Tab>
              <Tab onClick={() => setMonth('09')}>Setembro</Tab>
              <Tab onClick={() => setMonth('10')}>Outubro</Tab>
              <Tab onClick={() => setMonth('11')}>Novembro</Tab>
              <Tab onClick={() => setMonth('12')}>Dezembro</Tab>

            </TabList>

            <TabPanels>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => {
                return String(index + 1).padStart(2, '0') === month
                  ? (
                    <TabPanel key={item}>

                    {bills?.SALDO && bills?.year && Number(bills?.month) === Number(month) && Number(bills?.year) === Number(year)
                      ? (
                        <BillsTable data={bills}/>
                        )
                      : <Flex flexDirection="column" alignItems="center" justifyContent="center" my={10}>
                          <Text>Nenhuma conta cadastrada para este mês.</Text>
                          <Divider mt={10} />
                        </Flex>}

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" mt="50px">
                        {!bills?.SALDO && (
                          <DragAndDrop
                          label="Planilha de Custos"
                          ref={DragBillsRef}
                          defaultCover={undefined}
                          payload={{
                            name: 'aa',
                            type: 'blog-content'
                          }}
                        />
                        ) }

                        <DragAndDrop
                          label="Comprovante de Pagamento"
                          ref={DragRef}
                          defaultCover={undefined}
                          payload={{
                            name: 'aa',
                            type: 'blog-content'
                          }}
                        />

                        {bills?.SALDO && <Box />}
                      </SimpleGrid>

                    </TabPanel>
                    )
                  : <TabPanel key={item}><Text>sa</Text></TabPanel>
              })}

            </TabPanels>

          </Tabs>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="green.teal"
                _hover={{ bgColor: 'green.dark' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: 'green.light' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { FinancialHousesCreate }
