import {
  FormControl,
  FormLabel,
  Select as SelectChakra,
  SelectProps,
  FormErrorMessage
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface OptionsProps {
  value: string | number
  label: string
}
interface Props extends SelectProps {
  name: string
  label?: string
  errors?: any
  options: OptionsProps[]
  labelStyle?: any
  extraFunction?: (data?: any) => any
}

const BaseSelect: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  { name, label, errors = null, options, extraFunction, labelStyle, ...rest },
  ref
) => (
  <FormControl id={name} isInvalid={!(errors == null)}>
    {!!label && <FormLabel color="green.dark" style={labelStyle}>{label}</FormLabel>}
    <SelectChakra
      name={name}
      id={name}
      bg="white"
      variant="filled"
      border="1px"
      borderColor="blue.500"
      ref={ref}
      minHeight="48px"
      {...rest}
    >
      {options.map((i: OptionsProps) => (
        <option key={i.value} value={i.value} onClick={extraFunction}>
          {i.label}
        </option>
      ))}
    </SelectChakra>
    {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
  </FormControl>
)

export const Select = forwardRef(BaseSelect)
