import { Flex, Box, Avatar, Text } from "@chakra-ui/react";

interface ProfileProps{
    showProfileData?: boolean;
}
export function Profile({ showProfileData = true,  } : ProfileProps){
    return(
        <Flex align="center">
            {showProfileData && (
                 <Box
                 mr="4" textAlign="right">
                 <Text color="white">Diego Hennrich</Text>
                 <Text color="white" fontSize="small">
                     diegohennrich@gmail.com
                 </Text>
             </Box>
            )}
       
        <Avatar size="md" name="Diego Hennrich"/>
    </Flex>
    )
}