/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  NumberInput,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { TiDeleteOutline } from "react-icons/ti";
import {
  IHouseProps,
  IItemProps,
  IOptionProps,
  IPriceHouseProps,
  useConcierge,
} from "../../../Hooks/Concierge";
import { Input } from "../../Form/Input";
import { MoneyInput } from "../../Form/MoneyInput";
import { TextArea } from "../../Form/TextArea";
import { TablePlaceholder } from "../../TablePlaceholder";
import { getCurrencyValue } from "../../../utils/getCurrencyValue";

interface Props {
  data: IOptionProps;
  itemId: string;
  handleDeleteOption: (option: IOptionProps) => void;
  houses: IHouseProps[];
  onNameChange: (
    name: string,
    optionId: string,
    from?: number,
    to?: number,
    help?: string
  ) => void;
}

const ConciergeOptionContent: FC<Props> = ({
  data,
  itemId,
  handleDeleteOption,
  houses,
  onNameChange,
}) => {
  //   const [conciergeItemHouses, setConciergeItemHouses] = useState<IHouseProps[]>(houses)
  const { tempItem, updateTempItem } = useConcierge();

  const { register, watch } = useForm({
    // resolver: yupResolver(createFormSchema)
  });

  const name = watch(`name-option-${data.id}-item-${itemId}`);
  const help = watch(`help-option-${data.id}-item-${itemId}`);
  const from = watch(`from-option-${data.id}-item-${itemId}`);
  const to = watch(`to-option-${data.id}-item-${itemId}`);

  const handleSelectHouse = useCallback(
    ({ houseId, name }: { houseId: string; name: string }) => {
      const existsOption = tempItem?.options?.find((i) => i.id === data.id);

      if (!existsOption) return;

      const currentHouse = existsOption?.availability?.find(
        (i: IHouseProps) => i.id === houseId
      );

      if (currentHouse?.id) {
        const filteredAvailability = existsOption?.availability?.filter(
          (i: IHouseProps) => i.id !== houseId
        );
        const filteredPrices = existsOption?.prices?.filter(
          (i: IPriceHouseProps) => i.house.id !== houseId
        );

        const currentTempItem: IItemProps = { ...tempItem };
        const optionIndex = currentTempItem.options?.findIndex(
          (i) => i.id === data.id
        );

        if (optionIndex !== -1 && currentTempItem.options) {
          if (optionIndex === undefined) return;
          // Check if options is defined before accessing it
          const option = currentTempItem.options[optionIndex];
          if (option) {
            // Check if option is defined before accessing it
            option.availability = filteredAvailability;
            option.prices = filteredPrices;
          }
        }

        updateTempItem(currentTempItem);
      } else {
        const currentTempItem: IItemProps = { ...tempItem };
        const optionIndex = currentTempItem.options?.findIndex(
          (i) => i.id === data.id
        );

        if (optionIndex !== -1 && currentTempItem.options) {
          if (optionIndex === undefined) return;

          // Check if options is defined before accessing it
          const option = currentTempItem.options[optionIndex];

          if (option) {
            const payload = {
              house: { name, id: houseId },
              price: 0,
            };
            // Check if option is defined before accessing it
            option.availability = option.availability?.length
              ? [...option.availability, { name, id: houseId }]
              : [{ name, id: houseId }];
            option.prices = option.prices?.length
              ? [...option.prices, payload]
              : [payload];
          }
        }

        updateTempItem(currentTempItem);
      }
    },
    [tempItem, updateTempItem, data]
  );

  useEffect(() => {
    if (!name && !from && !to && !help) return;

    if (from || to) {
      onNameChange(`De ${from || 0} até ${to || 0}`, data.id, from, to, help);
    } else {
      onNameChange(name, data.id, from, to, help);
    }
  }, [name, from, to, help]);

  const option = useMemo(() => {
    return tempItem?.options?.find((i) => i.id === data.id);
  }, [tempItem, data.id]);

  const handleChangeValue = useCallback(
    (house: IHouseProps, value: string) => {
      const normalizeValue = getCurrencyValue(value);

      const existsOption = tempItem?.options?.find((i) => i.id === data.id);

      if (!existsOption) return;

      const currentTempItem: IItemProps = { ...tempItem };
      const optionIndex = currentTempItem.options?.findIndex(
        (i) => i.id === data.id
      );

      if (optionIndex !== -1 && currentTempItem.options) {
        // Ensure that optionIndex is a valid number
        if (typeof optionIndex === "number") {
          const option = currentTempItem.options[optionIndex];

          if (option && option.prices) {
            const priceHouseIndex = option.prices.findIndex(
              (i) => i.house.id === house.id
            );

            if (priceHouseIndex !== -1) {
              // Ensure that priceHouseIndex is a valid number
              if (typeof priceHouseIndex === "number") {
                option.prices[priceHouseIndex].price = normalizeValue;
              }
            }
          }
        }
      }

      updateTempItem(currentTempItem);
    },
    [tempItem, data, updateTempItem]
  );

  return (
    <Tabs variant="soft-rounded" mt="15px">
      <TabList>
        <Tab>Informações</Tab>
        <Tab>Disponibilidade</Tab>
        <Tab>Tabela de preços</Tab>
        <Button
          variant="ghost"
          ml="auto"
          as="button"
          size="sm"
          fontSize="md"
          width="200px"
          color="green.primary"
          leftIcon={
            <Icon as={TiDeleteOutline} fontSize="25" color="green.primary" />
          }
          onClick={() => handleDeleteOption(data)}
        >
          Excluir Opção
        </Button>
      </TabList>
      <TabPanels>
        <TabPanel mt="20px">
          {data.type === "RANGE" && (
            <Flex alignItems="center">
              <Input
                label="De"
                type="numeric"
                placeholder="Ex: 0"
                {...register(`from-option-${data.id}-item-${itemId}`)}
                defaultValue={data?.from}
                style={{ width: 70, textAlign: "center" }}
                FormControlStyle={{ width: "10%" }}
              />

              <Input
                label="Até"
                type="numeric"
                placeholder="Ex: 3"
                {...register(`to-option-${data.id}-item-${itemId}`)}
                defaultValue={data?.to}
                style={{ width: 70, textAlign: "center" }}
              />
            </Flex>
          )}

          {data.type === "DESCRIPTION" && (
            <Box>
              <Flex key={data.id} alignItems="flex-end" w="100%">
                <Input
                  label="Opção"
                  placeholder="Preencha a opção (Ex: Massagem Shiatsu)"
                  {...register(`name-option-${data.id}-item-${itemId}`)}
                  defaultValue={data?.name}
                />
              </Flex>

              <Flex mt="20px">
                <TextArea
                  label="Texto de ajuda"
                  placeholder="Preencha um texto de ajuda os esclarecimentos ao usuário"
                  {...register(`help-option-${data.id}-item-${itemId}`)}
                  minHeight={100}
                  _focus={{ backgroundColor: "#fff" }}
                  _hover={{ backgroundColor: "#fff" }}
                  defaultValue={data?.help}
                />
              </Flex>
            </Box>
          )}
        </TabPanel>
        <TabPanel>
          <Text color="green.teal" fontSize={18}>
            Em quais casas este <b>ITEM DE CONCIERGE</b> estará disponível?
          </Text>

          <SimpleGrid
            spacing={["12", "12"]}
            w="100%"
            minChildWidth="150px"
            mt="20px"
          >
            {houses.map((house) => (
              <Checkbox
                key={house.id}
                color="green.dark"
                value={house.id}
                name={`availability-house-${house.id}`}
                defaultChecked
                colorScheme="whatsapp"
                style={{ padding: 5, color: "#000" }}
                onChange={(e) =>
                  handleSelectHouse({ houseId: house.id, name: house.name })
                }
              >
                {house.name}
              </Checkbox>
            ))}
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <Table mt="30px">
            <Thead>
              <Tr>
                <Th textAlign="left" backgroundColor="green.dark" color="white">
                  Nome
                </Th>
                <Th backgroundColor="green.dark" color="white">
                  Preço Base
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {!option?.availability?.length && (
                <TablePlaceholder title="Você não marcou nenhuma casa com disponibilidade para este item de concierge." />
              )}
              {option?.availability?.map((house) => (
                <Tr key={house.id}>
                  <Td>
                    <Text fontWeight="bold" color="green.dark">
                      {house.name}
                    </Text>
                  </Td>
                  <Td>
                    <MoneyInput
                      label={
                        data.type === "DESCRIPTION"
                          ? "Preço"
                          : "Preço - por unidade"
                      }
                      placeholder="Preencha o valor"
                      // extraFunction={(e: any) => CalculateBDI(item, e)}
                      // errors={errors.name}
                      // defaultValue={quota?.price ? Number(quota?.price) : null}
                      {...register(`price-house-${house.id}`)}
                      onChange={(e: any) =>
                        handleChangeValue(house, e.target.value)
                      }
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export { ConciergeOptionContent };
