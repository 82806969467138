/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
} from "@chakra-ui/react";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import {
  DragAndDrop,
  DragAndDropExportedProps,
} from "../../Components/Form/DragAndDrop";
import { Input } from "../../Components/Form/Input";
import { Header } from "../../Components/Header";
import { Sidebar } from "../../Components/Sidebar";
import api from "../../Services/api";

import { RiAddLine, RiPencilLine } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import { Select } from "../../Components/Form/Select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TextArea } from "../../Components/Form/TextArea";
import {
  ITEMS_MULTI,
  ITEMS_TYPE,
  PROPERTIES_STATUS,
} from "../../Services/contants";
import IFilesProps from "../../interfaces/IFilesProps";
import { Loader } from "../../Components/Loader";
import { AiOutlineDelete } from "react-icons/ai";
import { ConciergeItemModal } from "../../Components/Modals/ConciergeItemModal";
import {
  IConciergeProps,
  IHouseProps,
  IItemProps,
  IPriceHouseProps,
  useConcierge,
} from "../../Hooks/Concierge";
import { IPropertiesProps } from "../../interfaces/IPropertiesProps";
import { TablePlaceholder } from "../../Components/TablePlaceholder";
import { AlertDialogCustom } from "../../Components/AlertDialog";
import { MoneyInput } from "../../Components/Form/MoneyInput";
import { getCurrencyValue } from "../../utils/getCurrencyValue";

interface OptionsProps {
  label: string;
  value: string;
}

interface OptionProps {
  id: string;
  name?: string;
  help?: string;
}

interface RulesOptions {
  id: string;
  description: string;
}

interface ItemProps {
  id: string;
  name?: string;
  multi?: "YES" | "NO" | undefined; // utilizado quando o tipo for descritivo
  type?: "NUMERIC" | "DESCRIPTION" | "RANGE" | undefined;
  description?: string;
  min?: number | undefined; // utilizado quando o tipo for numérico
  max?: number | undefined; // utilizado quando o tipo for numérico
  options?: OptionProps[];
  availability: IHouseProps[];
}

interface ServiceProps {
  name: string;
  items: ItemProps[];
  rules?: RulesOptions[];
  files: IFilesProps[];
}

const multiOptions = [
  {
    label: "Selecione",
    value: "",
  },
  {
    label: "Sim",
    value: "YES",
  },
  {
    label: "Não",
    value: "NO",
  },
];

const typeOptions = [
  {
    label: "Selecione",
    value: "",
  },
  {
    label: "Campo Numérico",
    value: "NUMERIC",
  },
  {
    label: "Campo Descritivo",
    value: "DESCRIPTION",
  },
];

const ConciergeCreate: FC = () => {
  const [loading, setLoading] = useState(false);
  const [tmpName, setTmpName] = useState("");
  const [initialCover, setInitialCover] = useState(undefined);
  const params = useParams();
  const [playlist, setPlaylist] = useState<any>({});
  const [serviceData, setServiceData] = useState<ServiceProps>(
    {} as ServiceProps
  );
  const [items, setItems] = useState<ItemProps[]>([]);
  const [rules, setRules] = useState<RulesOptions[]>([]);
  const [options, setOptions] = useState<OptionProps[]>([]);
  const [postCover, setPostCover] = useState<undefined | string>(undefined);
  const [files, setFiles] = useState<IFilesProps[]>();
  const toast = useToast();
  const CoverRef = useRef<DragAndDropExportedProps>(null);
  const [showConciergeItemModal, setShowConciergeItemModal] = useState({
    show: false,
    item: "",
  });
  const [houses, setHouses] = useState<IHouseProps[]>([]);
  const { updateConcierge, concierge } = useConcierge();
  const [selectedItem, setSelectedItem] = useState<IItemProps>(
    {} as IItemProps
  );

  const navigate = useNavigate();

  const { register, handleSubmit, formState, setValue, watch, unregister } =
    useForm({
      // resolver: yupResolver(createFormSchema)
    });
  const { errors } = formState;

  const validateFields = useCallback((payload: any) => {
    if (!payload.name || payload.name.trim() === "") {
      return "O Nome do serviço é obrigatório";
    }

    if (!payload.items || payload.items.length === 0) {
      return "Preencha pelo menos 1 opcional para o serviço.";
    }

    for (const item of payload.items) {
      if (!item.name || item.name.trim() === "") {
        return "Preencha pelo menos o nome de todas as opções adicionadas.";
      }
      if (!item.multi || !item.type) {
        return 'Selecione o campo "Tipo de Opcional" e "Multi Seleção" de todos os opcionais ';
      }
      if (item.type === "NUMERIC" && (item.min === "" || item.max === "")) {
        return "Para opcionais com tipo 'CAMPO NÚMERICO', é necessário preencher os campos 'mínimo' e 'máximo'.";
      }

      if (item.type === "DESCRIPTION" && item.options.length === 0) {
        return "Preencha pelo menos uma opção para o opcional do tipo 'CAMPO DESCRITIVO'.";
      }

      if (item.options && item.options.length > 0) {
        for (const option of item.options) {
          if (!option.name || option.name.trim() === "") {
            return "Cada opção precisa ter o campo nome preenchido.";
          }
        }
      }
    }

    return true; // O payload é válido
  }, []);

  const handeCreateNewData: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log("concierge: ", JSON.stringify(concierge));
      console.log("values: ", values);

      setLoading(true);
      try {
        // const payload = {
        //   name: values.name,
        //   need_time: "YES",
        //   items: items.map((item) => {
        //     const payloadItem = {
        //       id: item.id,
        //       name: values[`name-item-${item.id}`],
        //       description: values[`description-item-${item.id}`],
        //       type: values[`type-item-${item.id}`],
        //       multi:
        //         values[`type-item-${item.id}`] === ITEMS_TYPE.NUMERIC ||
        //         values[`type-item-${item.id}`] === ITEMS_TYPE.RANGE
        //           ? ITEMS_MULTI.NO
        //           : values[`multi-item-${item.id}`],
        //       min: values[`min-item-${item.id}`],
        //       max: values[`max-item-${item.id}`],
        //       options:
        //         values[`type-item-${item.id}`] === ITEMS_TYPE.NUMERIC
        //           ? []
        //           : item.options?.map((option) => {
        //               const payloadOption = {
        //                 id: option.id,
        //                 name: values[
        //                   `name-option-${option.id}-item-${item.id}`
        //                 ],
        //                 help: values[
        //                   `help-option-${option.id}-item-${item.id}`
        //                 ],
        //                 from: values[
        //                   `from-option-${option.id}-item-${item.id}`
        //                 ],
        //                 to: values[`to-option-${option.id}-item-${item.id}`],
        //               };

        //               return payloadOption;
        //             }),
        //     };

        //     return payloadItem;
        //   }),
        //   rules: rules?.map((rule) => ({
        //     id: rule.id,
        //     description: values[`description-rule-${rule.id}`],
        //   })),
        // };

        // const validationResult = validateFields(payload);
        const validationResult = true;
        if (validationResult) {
          console.log("O payload é válido.");
        } else {
          console.error("O payload é inválido:", validationResult);
          toast({
            title: validationResult,
            position: "top",
            isClosable: true,
            status: "error",
            variant: "solid",
          });
          setLoading(false);
          return;
        }

        let id = params?.id;

        if (id) {
          await api.put(`/concierges/${id}`, concierge);
        } else {
          const { data } = await api.post("/concierges", concierge);
          id = data?.concierges?.id;
        }

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [CoverRef];

        // console.log('allFiles:', allFiles)

        const normalizeInputsWithContent: Array<
          RefObject<DragAndDropExportedProps>
        > = allFiles.filter((i) => i.current?.selectedFile);

        console.log("normalizeInputsWithContent:", normalizeInputsWithContent);

        await Promise.all(
          normalizeInputsWithContent.map(
            async (
              inputDrag: RefObject<DragAndDropExportedProps>,
              index: number
            ) => {
              const cb =
                index === normalizeInputsWithContent?.length - 1
                  ? () => {
                      toast({
                        title: `${
                          params?.post_id ? "Editado" : "Cadastrado"
                        } com sucesso`,
                        position: "top",
                        isClosable: true,
                        status: "success",
                        variant: "solid",
                      });
                      navigate("/concierge");
                      setLoading(false);
                    }
                  : () => {};
              inputDrag?.current?.execute(`/files/${String(id)}`, cb);
            }
          )
        );

        if (!normalizeInputsWithContent?.length) {
          toast({
            title: params?.id
              ? "Editado com sucesso"
              : "Cadastrado com sucesso",
            position: "top",
            isClosable: true,
            status: "success",
            variant: "solid",
          });

          // navigate('/concierge')
          setLoading(false);
        }
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: "top",
          isClosable: true,
          status: "error",
          variant: "solid",
        });
        setLoading(false);
      }
    },
    [toast, params, navigate, items, validateFields, rules, concierge]
  );

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/concierges/${params?.id}`);
      const data: ServiceProps = resp.data;
      console.log("loadItem: ", resp.data);

      setValue("name", data.name);
      setServiceData(data);
      setItems(data?.items);

      setPostCover(
        data?.files?.find((i: any) => i.type === "concierge-cover")?.link
      );
      setFiles(data.files);

      console.log("data.files: ", data.files);
      data?.items?.forEach((item) => {
        setValue(`type-item-${item.id}`, item.type);
        setValue(`multi-item-${item.id}`, item.multi);
        setValue(`min-item-${item.id}`, item.min);
        setValue(`max-item-${item.id}`, item.max);
        setValue(`description-item-${item.id}`, item.description);

        item.options?.forEach((option) => {
          setValue(`name-option-${option.id}-item-${item.id}`, option.name);
          setValue(`help-option-${option.id}-item-${item.id}`, option.help);
        });
      });

      if (data?.rules?.length) {
        setRules(data?.rules);
        data?.rules?.map((rule) =>
          setValue(`description-rule-${rule.id}`, rule.description)
        );
      }
    } catch (error) {
      console.log("error edit: ", error);
      toast({
        title: error.response.data.message,
        position: "top",
        isClosable: true,
        status: "error",
        variant: "solid",
      });
    }
  }, [toast, setValue, params]);

  useEffect(() => {
    if (params.id) {
      loadItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleNewItem = useCallback(() => {
    const payload: ItemProps = {
      id: uuid(),
      name: "",
      type: undefined,
      multi: undefined,
      max: undefined,
      min: undefined,
      description: "",
      options: [],
      availability: [],
    };
    setItems((prev) => [...prev, payload]);
  }, []);

  const handleNewRule = useCallback(() => {
    const payload: RulesOptions = {
      id: uuid(),
      description: "",
    };
    setRules((prev) => [...prev, payload]);
  }, []);

  const handleNewOption = useCallback(
    (item_id: string) => {
      const item = items.findIndex((i) => i.id === item_id);

      const newArry = [...items];

      const currentItems = newArry[item].options;

      const payload: OptionProps = {
        id: uuid(),
        name: "",
        help: "",
      };

      newArry[item].options =
        currentItems != null ? [...currentItems, payload] : [payload];

      setItems([...newArry]);
    },
    [items]
  );

  const handleDeleteOption = useCallback(
    (option: OptionProps, item_id: string) => {
      const item = items.findIndex((i) => i.id === item_id);

      const newArry = [...items];

      const currentItems = newArry[item].options;

      newArry[item].options =
        currentItems !== null
          ? currentItems?.filter((i) => i.id !== option.id)
          : [];

      setItems([...newArry]);
    },
    [items]
  );

  const handleDeleteItem = useCallback(
    (item_id: string) => {
      const item = items.filter((i) => i.id !== item_id);

      setItems([...item]);
    },
    [items]
  );

  const handleDeleteRule = useCallback(
    (rule_id: string) => {
      const filtered = rules.filter((i) => i.id !== rule_id);

      setRules([...filtered]);
    },
    [rules]
  );

  const loadHouses = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/properties", {
        params: {
          pag: 1,
          active: PROPERTIES_STATUS.ACTIVE,
          all: true,
        },
      });

      const normalize: IHouseProps[] = data.data.map(
        (house: IPropertiesProps) => {
          return {
            id: house.id,
            name: house.name,
          };
        }
      );

      setHouses(normalize);
    } catch (error) {
      console.log("erro ao pegar lista: ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleInitialHouses = useCallback(() => {
    const currentConcierge: IConciergeProps = {
      info: {
        availability: houses,
        need_time: "YES",
        prices: houses.map((i: IHouseProps) => {
          return {
            house: {
              id: i.id,
              name: i.name,
            },
            price: 0,
          };
        }),
      },
    };

    updateConcierge(currentConcierge);
  }, [updateConcierge, houses]);

  useEffect(() => {
    if (houses.length) {
      handleInitialHouses();
    }
  }, [houses]);

  useEffect(() => {
    loadHouses();
  }, []);

  const handleChangeName = useCallback(() => {
    const current = { ...concierge };

    if (current.info) {
      current.info.name = tmpName; // Removed optional chaining here

      updateConcierge(current);
    }
  }, [updateConcierge, concierge, tmpName]);

  useEffect(() => {
    handleChangeName();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpName]);

  const handleSelectHouse = useCallback(
    ({ houseId, name }: { houseId: string; name: string }) => {
      const currentConcierge: IConciergeProps = { ...concierge };

      const exists = currentConcierge?.info?.availability?.find(
        (i: IHouseProps) => i.id === houseId
      );

      if (exists) {
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (currentConcierge.info && currentConcierge.info.availability) {
          currentConcierge.info.availability =
            currentConcierge.info.availability?.filter(
              (i: IHouseProps) => i.id !== houseId
            );
          currentConcierge.info.prices = currentConcierge.info.prices?.filter(
            (i: IPriceHouseProps) => i.house.id !== houseId
          );
          updateConcierge(currentConcierge);
        }
      } else {
        const payloadHouse = { id: houseId, name };
        if (
          currentConcierge.info &&
          currentConcierge.info.availability &&
          currentConcierge.info.prices
        ) {
          currentConcierge.info.availability = [
            ...currentConcierge.info.availability,
            payloadHouse,
          ];
          currentConcierge.info.prices = [
            ...currentConcierge.info.prices,
            { house: payloadHouse, price: 0 },
          ];
          updateConcierge(currentConcierge);
        }
      }
    },
    [concierge, updateConcierge]
  );

  const hanleDeleteConciergeItem = useCallback(async () => {
    if (!selectedItem || !selectedItem?.id) return;

    try {
      const current = { ...concierge };

      current.items = current?.items?.filter(
        (item) => item.id !== selectedItem.id
      );

      updateConcierge(current);
      setSelectedItem({} as IItemProps);
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: "top",
        isClosable: true,
        status: "error",
        variant: "solid",
      });
      setSelectedItem({} as IItemProps);
    }
  }, [selectedItem, toast, concierge, updateConcierge]);

  const closeItemConciergeModal = useCallback(() => {
    setSelectedItem({} as IItemProps);
  }, []);

  const handleChangeValue = useCallback(
    (house: IHouseProps, value: string) => {
      const normalizeValue = getCurrencyValue(value);

      const currentConcierge = { ...concierge };

      // const filteredAvailability = existsOption?.availability?.filter((i: IHouseProps) => i.id !== houseId)

      // const currentTempItem: IItemProps = { ...tempItem }
      const optionIndex = currentConcierge.info?.prices?.findIndex(
        (i) => i.house.id === house.id
      );

      if (optionIndex !== -1 && currentConcierge.info?.prices) {
        if (optionIndex === undefined) return;
        // Check if options is defined before accessing it
        const option = currentConcierge.info?.prices[optionIndex];
        if (option) {
          // Check if option is defined before accessing it
          option.price = normalizeValue;
        }
      }

      console.log("currentConcierge: ", currentConcierge);

      updateConcierge(currentConcierge);

      // updateTempItem(currentTempItem)
    },
    [concierge, updateConcierge]
  );

  const handleSelectHours = useCallback(
    (value: boolean) => {
      console.log("handleSelectHours: ", value);
      const currentConcierge = { ...concierge };

      if (currentConcierge.info) {
        if (value) {
          currentConcierge.info.need_time = "YES";
        } else {
          currentConcierge.info.need_time = "NO";
        }
      }

      updateConcierge(currentConcierge);
    },
    [concierge, updateConcierge]
  );

  return (
    <Box>
      <Header />
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar Item de Serviço de Concierge"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeItemConciergeModal}
          nextFunction={hanleDeleteConciergeItem}
        />
      )}

      {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        {showConciergeItemModal.show && (
          <ConciergeItemModal
            houses={concierge?.info?.availability ?? []}
            onClose={() => setShowConciergeItemModal({ item: "", show: false })}
          />
        )}

        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handeCreateNewData)}
        >
          <Heading size="lg" fontWeight="normal" color="green.dark">
            Criar novo Serviço de Concierge: {tmpName}
          </Heading>

          <Tabs variant="enclosed" mt="30px">
            <TabList>
              <Tab>Dados</Tab>
              <Tab>Itens</Tab>
              <Tab>Regulamento</Tab>
            </TabList>

            <TabPanels>
              <TabPanel mt="20px">
                <Tabs variant="soft-rounded">
                  <TabList>
                    <Tab>Informações</Tab>
                    <Tab>Disponibilidade</Tab>
                    <Tab>Tabela de preços</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel mt="30px">
                      <SimpleGrid
                        spacing={["6", "9"]}
                        w="100%"
                        minChildWidth="240px"
                      >
                        <DragAndDrop
                          ref={CoverRef}
                          label="Capa do Serviço"
                          containerStyle={{ width: "100%" }}
                          payload={{
                            name: "cover",
                            type: "concierge-cover",
                          }}
                          defaultCover={postCover}
                          id={
                            files?.length
                              ? files.find((i) => i.type === "concierge-cover")
                                  ?.id
                              : null
                          }
                        />

                        <Box>
                          <Input
                            label="Nome"
                            placeholder="Preencha o nome do Serviço (Ex: Massagem)"
                            // errors={errors.name}
                            {...register("name")}
                            onChange={(e) => setTmpName(e.target.value)}
                          />
                          <Checkbox
                            color="green.dark"
                            value="yes"
                            name=""
                            defaultChecked
                            colorScheme="whatsapp"
                            style={{ marginTop: 50, padding: 5, color: "#000" }}
                            onChange={(e) =>
                              handleSelectHours(e.target.checked)
                            }
                          >
                            Este serviço necessita agendamento de horário?
                          </Checkbox>
                        </Box>
                      </SimpleGrid>
                    </TabPanel>
                    <TabPanel>
                      <Text color="green.teal" fontSize={18}>
                        Em quais casas este <b>SERVIÇO</b> de concierge estará
                        disponível?
                      </Text>

                      <Divider
                        borderColor="#000"
                        backgroundColor="#000"
                        color="#000"
                        mb="20px"
                        mt="15px"
                      />
                      <SimpleGrid
                        spacing={["12", "12"]}
                        w="100%"
                        minChildWidth="150px"
                      >
                        {houses.map((house) => (
                          <Checkbox
                            key={house.id}
                            color="green.dark"
                            value={house.id}
                            name={`availability-house-${house.id}`}
                            defaultChecked
                            colorScheme="whatsapp"
                            style={{ padding: 5, color: "#000" }}
                            onChange={(e) =>
                              handleSelectHouse({
                                houseId: house.id,
                                name: house.name,
                              })
                            }
                          >
                            {house.name}
                          </Checkbox>
                        ))}
                      </SimpleGrid>
                    </TabPanel>

                    <TabPanel>
                      <Table mt="30px">
                        <Thead>
                          <Tr>
                            <Th
                              textAlign="left"
                              backgroundColor="green.dark"
                              color="white"
                            >
                              Nome
                            </Th>
                            <Th backgroundColor="green.dark" color="white">
                              Preço Base
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {!concierge?.info?.availability?.length && (
                            <TablePlaceholder title="Você não marcou nenhuma casa com disponibilidade para este serviço de concierge." />
                          )}
                          {concierge?.info?.availability?.map((house) => (
                            <Tr key={house.id}>
                              <Td>
                                <Text fontWeight="bold" color="green.dark">
                                  {house.name}
                                </Text>
                              </Td>
                              <Td>
                                <MoneyInput
                                  label="Preço"
                                  placeholder="Preencha o valor"
                                  // extraFunction={(e: any) => CalculateBDI(item, e)}
                                  // errors={errors.name}
                                  // defaultValue={quota?.price ? Number(quota?.price) : null}
                                  {...register(`price-house-${house.id}`)}
                                  onChange={(e: any) =>
                                    handleChangeValue(house, e.target.value)
                                  }
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </TabPanel>

              <TabPanel>
                <Flex justifyContent="flex-end">
                  <Button
                    onClick={() =>
                      setShowConciergeItemModal({
                        show: true,
                        item: "",
                      })
                    }
                    size="sm"
                    fontSize="sm"
                    bg="green.dark"
                    _hover={{ bgColor: "green.light" }}
                    color="white"
                    leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                  >
                    Criar novo Item
                  </Button>
                </Flex>
                <Table mt="30px">
                  <Thead>
                    <Tr>
                      <Th
                        textAlign="left"
                        backgroundColor="green.dark"
                        color="white"
                      >
                        Nome
                      </Th>
                      <Th backgroundColor="green.dark" color="white" w="8"></Th>
                    </Tr>
                  </Thead>

                  {concierge?.items?.length ? (
                    <Tbody>
                      {concierge?.items?.map((i) => (
                        <Tr key={i.id}>
                          <Td>
                            <Text fontWeight="bold" color="green.dark">
                              {i.name}
                            </Text>
                          </Td>

                          <Td>
                            <Flex>
                              <Button
                                as="a"
                                onClick={() => {}}
                                size="sm"
                                fontSize="sm"
                                mr="2"
                                bg="green.dark"
                                color="green.light"
                                leftIcon={
                                  <Icon as={RiPencilLine} fontSize="16" />
                                }
                              >
                                Editar
                              </Button>

                              <Button
                                as="button"
                                onClick={() => setSelectedItem(i)}
                                size="sm"
                                fontSize="sm"
                                bg="green.teal"
                                color="green.dark"
                                leftIcon={
                                  <Icon as={AiOutlineDelete} fontSize="16" />
                                }
                              >
                                Excluir
                              </Button>
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  ) : (
                    <TablePlaceholder title="Você ainda não possui nenhum item de concierge cadastrado para este serviço." />
                  )}
                </Table>
              </TabPanel>

              <TabPanel>
                <p>three!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>

          {/* <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <DragAndDrop
                ref={CoverRef}
                label="Capa do Serviço"
                containerStyle={{ width: '100%' }}
                payload={{
                  name: 'cover',
                  type: 'concierge-cover'
                }}
                defaultCover={postCover}
                id={files?.length ? files.find(i => i.type === 'concierge-cover')?.id : null}
              />

              <Box />
              </SimpleGrid>

            <VStack spacing={['9', '9']} mb="5" mt="40px">
              <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
                <Input
                  label="Nome"
                  placeholder="Preencha o nome do Serviço (Ex: Massagem)"
                  // errors={errors.name}
                  {...register('name')}
                />
              </SimpleGrid>

            </VStack>

              <Heading size="md" fontWeight="normal" color="green.teal" my="6" mt="0">
                Opcionais
              </Heading>

              {!items?.length && <Text mb="10" color="black.primary">Você ainda não possui nenhum opcional cadastrado para este serviço.</Text>}

              {items.map((item, indexItem: number) => (

                    <VStack spacing={['9', '9']} mb="5" bg="green.light" p="10" key={item.id} borderRadius="8px">
                              <Flex justifyContent="flex-end" w="100%">
                            <Button
                              ml="5"
                              as="button"
                              size="sm"
                              fontSize="md"
                              bg="red"
                              width="200px"
                              height="30px"
                              _hover={{ bgColor: 'gray.500' }}
                              color="white"
                              leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                              onClick={() => handleDeleteItem(item.id)}
                              >
                                Excluir todo item
                            </Button>
                            </Flex>

                      <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                      <Input
                        label="Nome"
                        placeholder="Ex: Tipo de Massagem"
                        // errors={errors.name}
                        {...register(`name-item-${item.id}`)}
                        defaultValue={item?.name}

                      />

                      {/* <TextArea
                        label="Descrição"
                        placeholder="Preencha a descrição"
                        {...register('description')}
                      /> */}
          {/* <Input
                        label="Descrição"
                        placeholder="Descrição ou observação (Ex: Mediante à consulta)"

                        {...register(`description-item-${item.id}`)}
                        helperText="Opcional"
                        defaultValue={item?.description}
                      />

                      </SimpleGrid>

                      <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                        <Select options={typeOptions} label='Tipo de opcional' {...register(`type-item-${item.id}`)} style={{ backgroundColor: '#fff' }} extraFunction={(e) => handleChangeSelectType(e?.target?.value, item.id)} defaultValue={item?.type} />

                        {!!(watch(`type-item-${item.id}`) === 'NUMERIC') && (
                          <>
                            <Input
                              label="Mínimo"
                              placeholder="Mínimo do campo numérco"

                              {...register(`min-item-${item.id}`)}
                              defaultValue={item?.min}

                            />

                          <Input
                              label="Máximo"
                              placeholder="Máximo do campo numérco"

                              {...register(`max-item-${item.id}`)}
                              defaultValue={item?.max}

                            />
                          </>
                        )}

                        {!!(watch(`type-item-${item.id}`) !== 'NUMERIC') && (
                          <Select options={multiOptions} label='O usuário pode selecionar mais de uma opção?' {...register(`multi-item-${item.id}`)} style={{ backgroundColor: '#fff' }} isDisabled={!!(watch(`type-item-${item.id}`) === 'NUMERIC')} defaultValue={item?.multi} />
                        )}

                      </SimpleGrid>

                      {item?.options?.length && <Divider borderColor="#000" backgroundColor="#000" color="#000" />}

                      {!!(watch(`type-item-${item.id}`) !== 'NUMERIC') && (
                            <SimpleGrid spacing={['12', '12']} w="100%" >

                            {item?.options?.map((option, index) => (
                              <>
                                <Flex key={option.id} alignItems="flex-end" w="100%">

                                  <Input
                                    label="Opção"
                                    placeholder="Preencha a opção (Ex: Massagem Shiatsu)"

                                    {...register(`name-option-${option.id}-item-${item.id}`)}
                                    defaultValue={option?.name}

                                  />

                                  <Button
                                      ml="5"
                                      as="button"
                                      size="sm"
                                      fontSize="md"
                                      bg="green.teal"
                                      width="200px"
                                      height="47px"
                                      _hover={{ bgColor: 'gray.500' }}
                                      color="white"
                                      leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                                      onClick={() => handleDeleteOption(option, item.id)}
                                      >
                                        Excluir Opção
                                    </Button>

                                </Flex>

                                <Flex>

                                <TextArea

                                  label="Texto de ajuda"
                                  placeholder="Preencha um texto de ajuda os esclarecimentos ao usuário"

                                  {...register(`help-option-${option.id}-item-${item.id}`)}
                                  minHeight={300}
                                  _focus={{ backgroundColor: '#fff' }}
                                  _hover={{ backgroundColor: '#fff' }}
                                  defaultValue={option?.help}
                                  />

                                </Flex>

                                {((item?.options) != null) && (index !== (item?.options?.length - 1)) && <Divider borderColor="#000" backgroundColor="#000" color="#000" />}
                              </>
                            ))}
                          </SimpleGrid>
                      )}

                      {!!(watch(`type-item-${item.id}`) !== 'NUMERIC') && <Button
                        width="100%"
                        as="button"
                        size="lg"
                        fontSize="sm"
                        bg="green.dark"
                        mb="10"
                        _hover={{ bgColor: 'green.teal' }}
                        color="green.light"
                        leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                        onClick={() => handleNewOption(item.id)}
                        >
                        Adicionar Opção
                      </Button>}

                    </VStack>

              ))} */}

          {/* <Button
                as="button"
                size="lg"
                fontSize="sm"
                bg="green.dark"
                mb="10"
                _hover={{ bgColor: 'green.teal' }}
                color="green.light"
                leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                onClick={handleNewItem}
                >
                Adicionar Opcional
              </Button>

            <Divider borderColor="#000" backgroundColor="#000" color="#000" marginBottom="30px" />

            <Heading size="md" fontWeight="normal" color="green.teal" my="6" mt="0">
              Regulamento
            </Heading>

            {!rules?.length && <Text mb="10" color="black.primary">Você ainda não possui nenhum regulamento cadastrado para este serviço.</Text>}

            {rules.map((rule) => (
              <VStack spacing={['9', '9']} mb="5" bg="green.light" p="10" key={rule.id} borderRadius="8px">
                <Flex key={rule.id} justifyContent="flex-end" w="100%">
                    <Button
                        ml="5"
                        as="button"
                        size="sm"
                        fontSize="md"
                        bg="green.teal"
                        width="200px"
                        height="37px"
                        _hover={{ bgColor: 'gray.500' }}
                        color="white"
                        leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                        onClick={() => handleDeleteRule(rule.id)}
                        >
                          Excluir Opção
                    </Button>
                </Flex>

                <SimpleGrid spacing={['12', '12']} w="100%" >
                  <Flex>
                    <TextArea
                      label="Regra"
                      placeholder="Ex: A duração deste serviço é de até 4 horas"
                      // errors={errors.short_description}
                      {...register(`description-rule-${rule.id}`)}
                      minHeight={300}
                      _focus={{ backgroundColor: '#fff' }}
                      _hover={{ backgroundColor: '#fff' }}
                      defaultValue={rule?.description}
                    />
                  </Flex>
                </SimpleGrid>
              </VStack>
            ))}

            <Button
              as="button"
              size="lg"
              fontSize="sm"
              bg="green.dark"
              mb="10"
              _hover={{ bgColor: 'green.teal' }}
              color="green.light"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              onClick={handleNewRule}
              >
              Adicionar Item de Regulamento
            </Button>

            <Heading size="md" fontWeight="normal" color="green.teal" my="6" mt="0">
              Tabela de preços
            </Heading> */}

          <Flex mt={["6", "8"]} justify="flex-end">
            <HStack>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <Button
                    as="a"
                    href="/concierge"
                    bg="green.teal"
                    _hover={{ bgColor: "gray.500" }}
                    color="white"
                    cursor="pointer"
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    bg="green.dark"
                    _hover={{ bgColor: "green.teal" }}
                    color="green.light"
                    isLoading={formState.isSubmitting}
                  >
                    Salvar
                  </Button>
                </>
              )}
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export { ConciergeCreate };
