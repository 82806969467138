/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { RiAddLine } from "react-icons/ri";
import { v4 as uuid } from "uuid";
import {
  IHouseProps,
  IItemProps,
  IItemType,
  IOptionProps,
  multiOptionsConcierge,
  typeOptionsConcierge,
  useConcierge,
} from "../../../Hooks/Concierge";
import { ITEMS_MULTI, ITEMS_TYPE } from "../../../Services/contants";
import { ConciergeOptionContent } from "../../Concierge/ConciergeOptionContent";
import { Input } from "../../Form/Input";
import { Select } from "../../Form/Select";

interface Props {
  onClose: (data?: any) => void;
  id?: string;
  houses: IHouseProps[];
}

const ConciergeItemModal: FC<Props> = ({ onClose, id, houses }) => {
  const [loading, setLoading] = useState(false);

  const [item, setItem] = useState<IItemProps>({
    id: id || uuid(),
  } as IItemProps);
  const toast = useToast();
  const { concierge, updateConcierge, tempItem, updateTempItem } =
    useConcierge();

  const { register, handleSubmit, formState, setValue, watch, unregister } =
    useForm({
      // resolver: yupResolver(createFormSchema)
    });

  const validateItemFields = useCallback(
    (payload: IItemProps) => {
      console.log("validateItemFields: ", payload);

      if (payload.name == null || payload.name.trim() === "") {
        return "Preencha o nome do item de concierge.";
      }
      if (!payload.multi || !payload.type) {
        return 'Selecione o campo "Tipo de Opcional" e "Multi Seleção" do item de concierge. ';
      }
      if (
        payload.type === "NUMERIC" &&
        (payload.min !== 0 || payload.max !== 0)
      ) {
        return "Para opcionais com tipo 'CAMPO NÚMERICO', é necessário preencher os campos 'mínimo' e 'máximo'.";
      }

      if (payload.type === "DESCRIPTION" && item?.options?.length === 0) {
        return "Preencha pelo menos uma opção para o opcional do tipo 'CAMPO DESCRITIVO'.";
      }

      if (!payload?.options) {
        return "O item de concierge precisa ter pelo menos 1 opcional";
      }

      if (payload.options != null && payload?.options?.length > 0) {
        for (const option of payload.options) {
          if (!option.name || option.name.trim() === "") {
            return "Todos os opcionais devem ter pelo menos o campo nome preenchido.";
          }

          // if (!option.availability?.length || option.name.trim() === '') {
          //   return 'Todos os opcionais devem ter pelo menos o campo nome preenchido.'
          // }
        }
      }

      return true; // O payload é válido
    },
    [item]
  );

  const handeCreateNewData: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log("tempItem: ", tempItem);
      console.log("values: ", values);

      setLoading(true);
      try {
        const payload: IItemProps = {
          id: item.id,
          name: values[`name-item-${item.id}`],
          description: values[`description-item-${item.id}`],
          type: values[`type-item-${item.id}`],
          multi:
            values[`type-item-${item.id}`] === ITEMS_TYPE.NUMERIC ||
            values[`type-item-${item.id}`] === ITEMS_TYPE.RANGE
              ? ITEMS_MULTI.NO
              : values[`multi-item-${item.id}`],
          min: values[`min-item-${item.id}`],
          max: values[`max-item-${item.id}`],
          options:
            values[`type-item-${item.id}`] === ITEMS_TYPE.NUMERIC
              ? []
              : tempItem?.options,
        };

        console.log("payload: ", payload);

        const validationResult = validateItemFields(payload);

        if (validationResult === true) {
          console.log("O payload é válido.");
        } else {
          console.error("O payload é inválido:", validationResult);
          toast({
            title: validationResult,
            position: "top",
            isClosable: true,
            status: "error",
            variant: "solid",
          });
          setLoading(false);
          return;
        }

        const current = { ...concierge };

        current.items = current?.items?.length
          ? [...current.items, payload]
          : [payload];
        updateConcierge(current);
        updateTempItem({} as IItemProps);
        onClose();
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: "top",
          isClosable: true,
          status: "error",
          variant: "solid",
        });
        setLoading(false);
      }
    },
    [
      toast,
      item,
      validateItemFields,
      concierge,
      updateConcierge,
      onClose,
      tempItem,
      updateTempItem,
    ]
  );

  const handleDeleteOption = useCallback(
    (option: IOptionProps) => {
      const currentItem = { ...tempItem };

      currentItem.options = currentItem?.options?.filter(
        (i) => i.id !== option.id
      );
      // setItem(currentItem)
      updateTempItem(currentItem);
    },
    [tempItem, updateTempItem]
  );

  const handleNewOption = useCallback(
    (type: IItemType) => {
      const currentTempItem = { ...tempItem };

      // const currentItem = { ...item }

      const payload: IOptionProps = {
        id: uuid(),
        name: "",
        help: "",
        from: 0,
        to: 0,
        type,
        availability: concierge.info?.availability,
        prices: concierge.info?.availability?.map((item) => {
          return {
            house: {
              id: item.id,
              name: item.name,
            },
            price: 0,
          };
        }),
      };

      // currentItem.options = currentItem.options?.length ? [...currentItem.options, payload] : [payload]

      // setItem(currentItem)
      currentTempItem.options = currentTempItem.options?.length
        ? [...currentTempItem.options, payload]
        : [payload];
      updateTempItem(currentTempItem);
    },
    [tempItem, updateTempItem, concierge]
  );

  const handleChangeSelectType = useCallback(
    (value: string, itemId: string) => {
      unregister(`multi-item-${itemId}`);
    },
    [unregister]
  );

  const onNameChange = useCallback(
    (
      name: string,
      optionId: string,
      from?: number,
      to?: number,
      help?: string
    ) => {
      const option = tempItem?.options?.findIndex((i) => i.id === optionId);

      if (option == null) return;

      const currentItemOptions = tempItem?.options?.length
        ? [...tempItem.options]
        : [];

      if (!currentItemOptions[option]) return;

      currentItemOptions[option].name = name;
      currentItemOptions[option].from = from;
      currentItemOptions[option].to = to;
      currentItemOptions[option].help = help;

      const currentItem = { ...tempItem };
      currentItem.options = currentItemOptions;

      // setItem(currentItem)
      updateTempItem(currentItem);
    },
    [tempItem, updateTempItem]
  );

  return (
    <>
      <Modal isOpen onClose={onClose} size="6xl">
        <ModalOverlay />
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handeCreateNewData)}
        >
          <ModalContent>
            <ModalHeader mb="0" pb="0">
              Novo Item de Concierge
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <VStack spacing={["9", "9"]} mb="5">
                <SimpleGrid
                  spacing={["12", "12"]}
                  w="100%"
                  minChildWidth="240px"
                >
                  <Input
                    label="Nome"
                    placeholder="Ex: Tipo de Massagem"
                    // errors={errors.name}
                    {...register(`name-item-${item.id}`)}
                    defaultValue={item?.name}
                  />

                  <Input
                    label="Descrição"
                    placeholder="Descrição ou observação (Ex: Mediante à consulta)"
                    {...register(`description-item-${item.id}`)}
                    // {...register('description-item')}
                    helperText="Opcional"
                    defaultValue={item?.description}
                  />
                </SimpleGrid>

                <SimpleGrid
                  spacing={["12", "12"]}
                  w="100%"
                  minChildWidth="240px"
                >
                  <Select
                    options={typeOptionsConcierge}
                    label="Tipo de opcional"
                    // {...register('type-item')}
                    style={{ backgroundColor: "#fff" }}
                    // extraFunction={(e) => {}}

                    {...register(`type-item-${item.id}`)}
                    extraFunction={(e) =>
                      handleChangeSelectType(e?.target?.value, item.id)
                    }
                    defaultValue={item?.type}
                  />

                  {!!(watch(`type-item-${item.id}`) === "NUMERIC") && (
                    <>
                      <Input
                        label="Mínimo"
                        placeholder="Mínimo do campo numérco"
                        {...register(`min-item-${item.id}`)}
                        defaultValue={item?.min}
                      />

                      <Input
                        label="Máximo"
                        placeholder="Máximo do campo numérco"
                        {...register(`max-item-${item.id}`)}
                        defaultValue={item?.max}
                      />
                    </>
                  )}

                  {watch(`type-item-${item.id}`) === "DESCRIPTION" ? (
                    <Select
                      options={multiOptionsConcierge}
                      label="O usuário pode selecionar mais de uma opção?"
                      {...register(`multi-item-${item.id}`)}
                      style={{ backgroundColor: "#fff" }}
                      isDisabled={
                        !!(watch(`type-item-${item.id}`) === "NUMERIC")
                      }
                      defaultValue={item?.multi}
                    />
                  ) : (
                    <Box />
                  )}
                </SimpleGrid>

                {(!!(watch(`type-item-${item.id}`) === "DESCRIPTION") ||
                  !!(watch(`type-item-${item.id}`) === "RANGE")) && (
                  <Heading
                    textAlign="left"
                    size="md"
                    fontWeight="normal"
                    color="green.dark"
                    width="100%"
                  >
                    Opcionais deste item de concierge:
                  </Heading>
                )}

                {/* <Divider borderColor="#000" backgroundColor="#000" color="#000" mb="20px" mt="20px" /> */}

                {(!!(watch(`type-item-${item.id}`) === "DESCRIPTION") ||
                  !!(watch(`type-item-${item.id}`) === "RANGE")) && (
                  <SimpleGrid spacing={["12", "12"]} w="100%">
                    <Accordion defaultIndex={[0]} allowMultiple>
                      {tempItem?.options?.map((option, index) => (
                        <AccordionItem key={option.id}>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              {option?.name || "Novo opcional"}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>

                          <AccordionPanel pb={4}>
                            <ConciergeOptionContent
                              data={option}
                              houses={houses}
                              handleDeleteOption={handleDeleteOption}
                              itemId={tempItem.id}
                              key={option.id}
                              onNameChange={onNameChange}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </SimpleGrid>
                )}

                {(!!(watch(`type-item-${item.id}`) === "DESCRIPTION") ||
                  !!(watch(`type-item-${item.id}`) === "RANGE")) && (
                  <Button
                    width="100%"
                    as="button"
                    size="lg"
                    fontSize="sm"
                    bg="green.dark"
                    mb="10"
                    _hover={{ bgColor: "green.teal" }}
                    color="green.light"
                    leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                    onClick={() =>
                      handleNewOption(watch(`type-item-${item.id}`))
                    }
                  >
                    Adicionar Opção
                  </Button>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="green.dark"
                _hover={{ bgColor: "green.teal" }}
                color="green.light"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
              {/* <Button variant='ghost' onClick={handeCreateNewData}>Cadastrar</Button> */}
            </ModalFooter>
          </ModalContent>
        </Box>
      </Modal>
    </>
  );
};

export { ConciergeItemModal };
