import { Table, Thead, Tr, Th, Td, Tbody, Link, Text, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import IBillsHouseImportedDTO from '../../interfaces/IBiilsHouseImportedDTO'
import { FormatMoney } from '../../utils/formatMoney'

interface Props {
  data: IBillsHouseImportedDTO
}

const BillsTable: FC<Props> = ({ data }) => {
  return (
        <Table colorScheme="blue">
            <Thead>
                <Tr>

                <Th textAlign="left" backgroundColor="green.dark" color="white">Nome</Th>
                <Th textAlign="left" backgroundColor="green.dark" color="white">Valor</Th>

                {/* <Th w="8"></Th> */}
                </Tr>
            </Thead>
            <Tbody>
            <Tr bg="green.light">
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black" >SALDO INICIAL</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(data['SALDO INICIAL'])}</Text>
                    </Link>
                </Td>
            </Tr>

            <Tr bg="green.light">
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">RECEITAS</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(data?.RECEITAS?.total)}</Text>
                    </Link>
                </Td>
            </Tr>

            {data?.RECEITAS?.dados?.map(item => (
                <Tr bg="gray.100" key={item?.conta}>
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black" ml="15px">{item?.conta}</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(item?.valor)}</Text>
                    </Link>
                </Td>
            </Tr>
            ))}

            <Tr bg="green.light">
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">DESPESAS</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(data?.DESPESAS?.total)}</Text>
                    </Link>
                </Td>
            </Tr>

            {data?.DESPESAS?.dados?.map(item => (
                <Tr bg="gray.100" key={item?.conta}>
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black" ml="15px">{item?.conta}</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(item?.valor)}</Text>
                    </Link>
                </Td>
            </Tr>
            ))}

            <Tr bg="green.light">
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">SALDO</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(data?.SALDO)}</Text>
                    </Link>
                </Td>
            </Tr>

            <Tr bg="green.light">
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">SALDO FINAL</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(data?.['SALDO FINAL'])}</Text>
                    </Link>
                </Td>
            </Tr>

            <Tr bg="green.light">
                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">FUNDO DE RESERVA ACUMULADO</Text>
                    </Link>
                </Td>

                <Td>
                    <Link color="wine.500" textAlign="left">
                    <Text fontWeight="700" color="black">{FormatMoney(data?.['FUNDO DE RESERVA ACUMULADO'])}</Text>
                    </Link>
                </Td>
            </Tr>

            </Tbody>
        </Table>
  )
}

export { BillsTable }
