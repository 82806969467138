import { Box, HStack, Icon, IconButton } from '@chakra-ui/react'
import { RiNotificationLine, RiUserAddLine } from 'react-icons/ri'
import { AiOutlineLogout } from 'react-icons/ai'
import { useAuth } from '../../Hooks/Auth'
import { useNavigate, useNavigation } from 'react-router-dom'

export function NotificationsNav () {
  const { signOut } = useAuth()
  const navigate = useNavigate()
  // console.log('header called')
  return (
    <HStack
      spacing={['6', '8']}
      mx={['6', '8']}
      pr={['6', '8']}
      py="1"
      color="white"
      borderRightWidth={1}
      borderColor="white"
    >

{/* <Box position="relative" display="inline-block">
      <IconButton
        icon={<Icon as={RiNotificationLine} fontSize="20" />}
        onClick={() => navigate('/notifications')}
        aria-label="Notifications"
        backgroundColor="transparent"
      />
      <Box
        position="absolute"
        top="-5px"
        right="-5px"
        rounded="full"
        bg={'green.500'} // Pode alterar a cor do badge aqui
        color="white"
        fontSize="10px"
        width="20px"
        height="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontWeight={700}
      >
        2
      </Box>
      </Box> */}

            {/* <Icon as={RiUserAddLine
            } fontSize="20" /> */}

      <IconButton
        icon={<Icon as={AiOutlineLogout} fontSize="20" />}
        onClick={signOut}
        aria-label="Sign Out"
        backgroundColor="transparent"
      />
    </HStack>
  )
}
