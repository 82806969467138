import React, { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { format, parseISO } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { Input } from "../../Components/Form/Input";
import { InputMaskCustom } from "../../Components/Form/InputMask";
import { Select } from "../../Components/Form/Select";
import { Header } from "../../Components/Header";
import { Sidebar } from "../../Components/Sidebar";
import api from "../../Services/api";
import { useLocation, useParams } from "react-router-dom";

interface createUserProps {
  index?: number;
  name: string;
  email: string;
  born: string;
  phone: string;
  gender: string;
  gym_id: string;
  password?: string;
  zip_code?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  cpf?: string;
}

interface User {
  name: string;
  email: string;
  born: string;
  phone: string;
  gender: string;
  gym_id: string;
  cpf?: string;
  address?: {
    zip_code?: string;
    street?: string;
    number?: string;
    complement?: string;
    neighborhood?: string;
    city?: string;
    state?: string;
  };
}

interface Gym {
  id: string;
  name: string;
}

interface OptionsProps {
  label: string;
  value: string;
}

interface CepProps {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade?: string;
  uf: string;
}

const createUserFormSchema = Yup.object().shape({
  name: Yup.string().required("Nome é obrigatório"),
  email: Yup.string()
    .email("Preencha um e-mail válido")
    .required("E-mail é obrigatório"),
  born: Yup.string().required("Data de nascimento é obrigatório"),
  phone: Yup.string().required("Telefone é obrigatório"),

  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref("password")],
    "As senhas devem ser iguais"
  ),
  gym_id: Yup.string().required("Selecione uma unidade"),
  zip_code: Yup.string(),
  street: Yup.string()
    .ensure()
    .when("zip_code", {
      is: (exists: any) => exists,
      then: Yup.string().required("Preencha a Rua"),
    }),
  number: Yup.string()
    .ensure()
    .when("zip_code", {
      is: (exists: any) => exists,
      then: Yup.string().required("Preencha o número"),
    }),
  neighborhood: Yup.string()
    .ensure()
    .when("zip_code", {
      is: (exists: any) => exists,
      then: Yup.string().required("Preencha a bairro"),
    }),
  city: Yup.string()
    .ensure()
    .when("zip_code", {
      is: (exists: any) => exists,
      then: Yup.string().required("Preencha a cidade"),
    }),
  state: Yup.string()
    .ensure()
    .when("zip_code", {
      is: (exists: any) => exists,
      then: Yup.string().required("Preencha o Estado"),
    }),
});

const updateUserFormSchema = Yup.object().shape({
  name: Yup.string().required("Nome é obrigatório"),
  login: Yup.string().required("Login é obrigatório"),
  password: Yup.string(),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref("password")],
    "As senhas devem ser iguais"
  ),
  gym_id: Yup.string().required("Selecione uma unidade"),
});

const optionsGender = [
  {
    value: "male",
    label: "Masculino",
  },
  {
    value: "female",
    label: "Feminino",
  },
];

const UsersCreate: FC = () => {
  const [loading, setLoading] = useState(false);
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([]);
  const [showAddressComponent, setShowAddressComponent] = useState(false);
  const params = useParams();
  const location = useLocation();
  const toast = useToast();

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUserFormSchema),
  });
  const { errors } = formState;

  const handleSubmitForm: SubmitHandler<createUserProps> = useCallback(
    async (values) => {
      const {
        name,
        email,
        password,
        gym_id,
        born,
        phone,
        gender,
        street,
        zip_code,
        number,
        complement,
        neighborhood,
        city,
        state,
        cpf,
      } = values;

      const normalizePhone = String(`${phone}`.replace(/([^0-9]+)/g, ""));

      if (normalizePhone.length < 11) {
        toast({
          title: "Telefone inválido",
          position: "top",
          isClosable: true,
          status: "error",
          variant: "solid",
        });
        return;
      }

      const payload = {
        name,
        email,
        password: password || "123123",
        gym_id,
        born,
        phone: normalizePhone,
        gender,
        street,
        zip_code,
        number,
        complement,
        neighborhood,
        city,
        state,
        cpf,
      };

      //   const { user_id } = router.query;

      try {
        // user_id
        //   ? await api.put(`/user/${user_id}`, payload)
        //   : await api.post("/user", payload);
        const user_id = "aa";

        toast({
          title: `${user_id ? "Editado" : "Cadastrado"} com sucesso`,
          position: "top",
          isClosable: true,
          status: "success",
          variant: "solid",
        });
        // router.push("/users");
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: "top",
          isClosable: true,
          status: "error",
          variant: "solid",
        });
      }
    },
    [toast]
  );

  const loadItem = useCallback(async () => {
    try {
      //   const { user_id } = router.query;
      //   const resp = await api.get(`/user/${user_id}`);
      //   const data: User = resp.data;
      //   const born = parseISO(data.born);
      //   console.log("born: ", born);
      //   const bornNormalized = format(born, "dd/MM/yyyy");
      //   setValue("name", data.name);
      //   setValue("email", data.email);
      //   setValue("born", data.born ? bornNormalized : "");
      //   setValue(
      //     "phone",
      //     data.phone ? String(`${data.phone}`.replace(/([^0-9]+)/g, "")) : ""
      //   );
      //   // setValue('born', '01081989')
      //   setValue("cpf", data.cpf || "");
      //   // setValue('gender', data.gender)
      //   // setValue('password', data.password)
      //   setValue("gym_id", data.gym_id);
      //   if (data?.address?.zip_code) {
      //     setShowAddressComponent(true);
      //     setValue(
      //       "zip_code",
      //       String(`${data.address.zip_code}`.replace(/([^0-9]+)/g, ""))
      //     );
      //     setValue("street", data.address.street);
      //     setValue("number", data.address.number);
      //     if (data?.address?.complement) {
      //       setValue("complement", data.address.complement);
      //     }
      //     setValue("neighborhood", data.address.neighborhood);
      //     setValue("city", data.address.city);
      //     setValue("state", data.address.state);
      //   }
    } catch (error) {
      console.log("error edit: ", error);
      toast({
        title: error.response.data.message,
        position: "top",
        isClosable: true,
        status: "error",
        variant: "solid",
      });
    }
  }, [toast, setValue]);

  const loadGyms = useCallback(async () => {
    try {
      const resp = await api.get(`/gym`, {
        params: {
          pag: 1,
          all: true,
        },
      });
      const data: Gym[] = resp.data.data;

      const options = data.map((i: Gym) => ({
        label: i.name,
        value: i.id,
      }));

      setOptionsGym(options);
    } catch (error) {
      console.log("error gymm: ", error);
      toast({
        title: error.response.data.message,
        position: "top",
        isClosable: true,
        status: "error",
        variant: "solid",
      });
    }
  }, [toast]);

  useEffect(() => {
    loadGyms();
  }, []);

  //   useEffect(() => {
  //     if (router.query.user_id) {
  //       loadItem();
  //     }
  //   }, [router.query]);

  const handleSearchCep = useCallback(
    async (e: any) => {
      const cep = `${e}`.replace(/([^0-9]+)/g, "");

      if (Number(cep.length) === 8) {
        setLoading(true);
        toast({
          title: "Buscando Endereço",
          position: "top",
          isClosable: true,
          status: "info",
          variant: "solid",
        });
        try {
          const response = await api.get(`/cep/${cep}`);
          const { bairro, logradouro, uf, complemento, localidade }: CepProps =
            response.data;
          console.log("busca cep: ", response.data);

          setShowAddressComponent(true);

          setValue("street", logradouro);
          setValue("neighborhood", bairro);
          setValue("state", uf);
          setValue("city", localidade);

          if (complemento) {
            setValue("complement", complemento);
          }
        } catch (error) {
          console.log("error users cep: ", error);
          toast({
            title: error.response.data.message,
            position: "top",
            isClosable: true,
            status: "error",
            variant: "solid",
          });
        } finally {
          setLoading(false);
        }
      }
    },
    [setValue, toast]
  );

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          //   onSubmit={handleSubmit(handleSubmitForm)}
          onSubmit={() => {}}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="blue.500">
            Criar novo usuário
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <Heading size="md" fontWeight="normal" color="blue.500" my="6">
            Dados Cadastrais
          </Heading>

          <VStack spacing={["6", "8"]}>
            <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
              <Input
                label="Nome"
                placeholder="Preencha o nome"
                // errors={errors.name}
                {...register("name")}
              />
              <Input
                label="E-mail"
                placeholder="Preencha o e-mail do usuário"
                // errors={errors.email}
                {...register("email")}
              />
            </SimpleGrid>

            <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
              <InputMaskCustom
                type="text"
                label="Data de nascimento"
                // errors={errors.born}
                {...register("born")}
                placeholder="Digite data de nascimento"
                mask="99/99/9999"
              />

              <InputMaskCustom
                type="text"
                label="Telefone"
                // errors={errors.phone}
                {...register("phone")}
                placeholder="Digite o telefone"
                mask="(99)99999-9999"
              />
            </SimpleGrid>

            <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
              {/* <Select name="gender" label="Gênero" placeholder="Selecionar Gênero" errors={errors.gender} {...register('gender')} options={optionsGender} /> */}
              <InputMaskCustom
                type="text"
                label="CPF"
                // errors={errors.cpf}
                {...register("cpf")}
                placeholder="Digite o cpf"
                mask="999.999.999-99"
              />

              <Select
                label="Unidade"
                placeholder="Selecionar Unidade"
                // errors={errors.gym_id}
                {...register("gym_id")}
                options={optionsGym}
              />
            </SimpleGrid>

            <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
              <Input
                type="password"
                label="Senha"
                // errors={errors.password}
                {...register("password")}
                placeholder="Digite a senha"
                // helperText={
                //   router?.query?.user_id
                //     ? "Preencha a senha apenas se quiser alterá-la"
                //     : null
                // }
              />
              <Input
                type="password"
                label="Confirmar Senha"
                placeholder="Digite novamente a senha"
                // errors={errors.password_confirmation}
                {...register("password_confirmation")}
              />
            </SimpleGrid>
          </VStack>

          <Heading size="md" fontWeight="normal" color="blue.500" my="6">
            Dados de Endereço
          </Heading>

          <VStack spacing={["6", "8"]}>
            <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
              <InputMaskCustom
                type="text"
                label="CEP"
                // errors={errors.zip_code}
                {...register("zip_code")}
                placeholder="Digite o cep"
                mask="99999-999"
                onChange={(e) => handleSearchCep(e.target.value)}
              />
            </SimpleGrid>
            {loading && (
              <Flex justify="center">
                <Spinner />
              </Flex>
            )}

            {showAddressComponent && (
              <>
                <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
                  <Input
                    label="Rua"
                    placeholder="Preencha a rua"
                    // errors={errors.street}
                    {...register("street")}
                  />

                  <Input
                    label="Número"
                    placeholder="Preencha o número"
                    // errors={errors.number}
                    {...register("number")}
                  />
                </SimpleGrid>

                <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
                  <Input
                    label="Complemento"
                    placeholder="Preencha o Complemento"
                    // errors={errors.complement}
                    {...register("complement")}
                  />

                  <Input
                    label="Bairro"
                    placeholder="Preencha o bairro"
                    // errors={errors.neighborhood}
                    {...register("neighborhood")}
                  />
                </SimpleGrid>

                <SimpleGrid spacing={["6", "9"]} w="100%" minChildWidth="240px">
                  <Input
                    label="Cidade"
                    placeholder="Preencha a cidade"
                    // errors={errors.city}
                    {...register("city")}
                  />

                  <Input
                    label="Estado"
                    placeholder="Preencha o estado"
                    maxLength={2}
                    // errors={errors.state}
                    {...register("state")}
                  />
                </SimpleGrid>
              </>
            )}
          </VStack>

          <Flex mt={["6", "8"]} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: "gray.500" }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="blue.500"
                _hover={{ bgColor: "gray.500" }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export { UsersCreate };
