import { format } from 'date-fns'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import * as Yup from 'yup'

import { Box, Flex, Heading, Spinner, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

import api from '../../Services/api'
import { AlertDialogCustom } from '../AlertDialog'
import { useNavigate } from 'react-router-dom'
import ISchedulesProps from '../../interfaces/ISchedulesProps'
import { ModalAppointmentDetails } from '../ModalAppointmentDetails'
interface ClassEvent {
  title: string
  date: string
}

interface DaySelected {
  day: string
  formatted: string
}

const EventScheme = Yup.object().shape({
  name: Yup.string().required('Nome do evento é obrigatório'),
  teacher: Yup.string().required('Nome do professor é obrigatório'),
  hour: Yup.string().required('Horário de liberação é obrigatório'),
  link: Yup.string().required('Link do evento é obrigatório')
})

interface HolidayProps {
  title: string
  date?: string
  id: number
}

interface Props {
  schedule: ISchedulesProps[]
}

interface ItemSelectedProps {
  show: boolean
  id: string
}
const CalendarSchedule: FC<Props> = ({ schedule }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [datesSelected, setDatesSelected] = useState<DaySelected[]>([])
  const [customEvents, setCustomEvents] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ItemSelectedProps>({ show: false, id: '' })
  const toast = useToast()
  const navigate = useNavigate()
  console.log('schedule: ', schedule)

  const appointmentsData = useMemo(() => {
    return schedule.map(item => {
      return {
        title: `${item.property.name} - ${item.user.name}`,
        start: format(new Date(item.date_initial), 'yyyy-MM-dd'),
        end: format(new Date(item.date_final), 'yyyy-MM-dd'),
        id: item.id
      }
    })
  }, [schedule])

  // const loadEvents = useCallback(async () => {
  //   //     const payload = [
  //   //         { title: e.name, date: dateSelected.day, id: 20}
  //   //     ];

  //   //     setCustomEvents((prev: any) => [...prev, ...payload])
  //   try {
  //     const { data } = await api.get('/holiday', {
  //       params: {
  //         pag: 1,
  //         all: true
  //       }
  //     })

  //     setCustomEvents(data.data)
  //   } catch (error) {
  //     toast({
  //       title: error.response.data.message,
  //       position: 'top',
  //       isClosable: true,
  //       status: 'error',
  //       variant: 'solid'
  //     })
  //   }
  // }, [toast])

  // const hanleDelete = useCallback(async () => {
  //   const { id } = selectedItem
  //   try {
  //     await api.delete(`/holiday/${id}`)

  //     toast({
  //       title: 'Deletado com sucesso',
  //       position: 'top',
  //       isClosable: true,
  //       status: 'success',
  //       variant: 'solid'
  //     })
  //     setSelectedItem({} as HolidayProps)
  //     // loadEvents()
  //   } catch (error) {
  //     toast({
  //       title: error.response.data.message,
  //       position: 'top',
  //       isClosable: true,
  //       status: 'error',
  //       variant: 'solid'
  //     })
  //     setSelectedItem({} as HolidayProps)
  //   }
  // }, [selectedItem, toast])

  // const closeModal = useCallback(() => {
  //   setSelectedItem({} as HolidayProps)
  // }, [])

  const handleUpdateEvent = useCallback((e: any) => {
    // setValue('name', 'value', { shouldValidate: true })
    console.log('update: ', e.event)
    setSelectedItem({
      id: e.event._def.publicId,
      show: true
    })
  }, [])

  // useEffect(() => {
  //   loadEvents()
  // }, [])
  console.log('appointmentsData: ', appointmentsData)

  return (
    <Box>
      {selectedItem.show && <ModalAppointmentDetails appointment_id={selectedItem?.id} onClose={() => setSelectedItem({ show: false, id: '' })} />}

      {((schedule?.length) !== 0) && (
        <FullCalendar
          // innerRef={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          weekends
          selectable
          events={appointmentsData}
          // dateClick={handleSelect}
          eventClick={handleUpdateEvent}
          locale="pt"
        />
      )}
    </Box>

  )
}

export { CalendarSchedule }
